.clienttable_row td:nth-child(even) {
  display: table-cell;
}

.table-margin {
  border-spacing: 0 6px;
  border-collapse: separate;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 2px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey !important; */
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #999999 !important; */
  border-radius: 100px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb {
  background: #999999 !important;
}

/* .tableFixHead {
  overflow: auto;
  height: inherit;
} */

/* .tableFixHead thead,
.tableFixHead tbody tr {
  display: table;
  min-width: 100%; 
  table-layout: fixed;
} */

.tbody {
  display: block;
  overflow: auto;
  height: calc(100vh - 320 * 1px);
}

.tbody-cust {
  display: block;
  overflow: auto;
  height: calc(100vh - 353 * 1px);
}

.tbody-cust-rep {
  display: block;
  overflow: auto;
  height: calc(100vh - 325 * 1px);
}

.tbody_analysis {
  display: block;
  overflow: auto;
  height: calc(100vh - 305 * 1px);
}

.tbody_rda {
  display: block;
  overflow-y: scroll;
  height: calc(
    100vh - (var(--title-height) + var(--header-height) + var(--table-header-height) + 90) * 1px
  );
}

.dashboard_height {
  display: block;
  overflow-y: scroll;
  height: calc(100vh - (var(--title-height) + 120) * 1px);
}

.history-table {
  display: block;
  overflow-y: scroll;
  height: calc(100vh - 350 * 1px);
}

.sale-table {
  display: block;
  overflow-y: scroll;
  height: calc(100vh - 347 * 1px);
}

.report-table {
  display: block;
  overflow-y: scroll;
  height: calc(100vh - 580 * 1px);
}

.audit-table {
  display: block;
  overflow-y: scroll;
  height: calc(100vh - 450 * 1px);
}

/* .thead,
.tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
} */

/* .tableFixHead th:first-child,
.tableFixHead td:first-child {
  width: 100%;
}

.tableFixHead th:not(:first-child),
.tableFixHead td:not(:first-child) {
  width: calc((100% - 10%) / 4);
} */

/* 
.fixed_header {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
}

.fixed_header tbody {
  display: block;
  overflow: auto;
  height: 250px;
}

.fixed_header thead tr {
  display: block;
  width: 100%;
} */

.tt thead th {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.vertical {
  border-left: 3px solid #cccccc;
  left: 58.4%;
  transform: rotate(180deg);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.diet-section-name {
  padding-left: 18px;
  padding: 0px 18px;
}

.main-div {
  margin-top: 18px;
  border-radius: 15px;
  padding: 8px;
}

.content-height {
  height: var(--user-panel-height);
}

.content-table {
  height: var(--plan-table-head-height);
}

/*change  74->40  */
.table-card {
  height: calc(100vh - 125 * 1px);
}

.table-client {
  height: calc(100vh - 260 * 1px);
}

.progress-table {
  height: calc(100vh - 225 * 1px);
}

.box-for-master {
  display: block;
  overflow: auto;
  height: calc(100vh - 465 * 1px);
}

.content-haf {
  height: calc(100vh - (var(--user-info) + 195) * 1px);
}

.ingredient-list {
  height: calc(100vh - 245 * 1px);
}

.ingredient-error {
  height: calc(100vh - 240 * 1px);
}

.ingredient-box {
  height: calc(100vh - 110 * 1px);
}

.content-diet-table {
  height: calc(100vh - (var(--plan-diet-heading-height) + var(--user-height) + 124) * 1px);
}

.content-diet-plan {
  height: calc(100vh - 351 * 1px);
}

.content-diet-plan1 {
  height: calc(100vh - (var(--user-height) + 253) * 1px);
}

.content-diet-plan2 {
  height: calc(100vh - (var(--user-height) + 225) * 1px);
}

.content-diet-plan3 {
  height: calc(100vh - 173 * 1px);
}

.content-diet-plan4 {
  height: calc(100vh - 162 * 1px);
}

.content-rda {
  height: calc(100vh - (var(--user-height) + 175) * 1px);
}

.reports {
  height: calc(100vh - (var(--user-height) + 225) * 1px);
}

.dna-reports {
  height: calc(100vh - (var(--user-height) + 225) * 1px);
}

.dnas-report {
  height: calc(100vh - (var(--user-height) + 255) * 1px);
}

.dnas-report1 {
  height: calc(100vh - (var(--user-height) + 325) * 1px);
}

.dnas-report2 {
  height: calc(100vh - (var(--user-height) + 325) * 1px);
}

.note {
  height: calc(100vh - (var(--user-height) + 485) * 1px) !important;
}

.expert-note {
  height: calc(100vh - (var(--user-height) + 320) * 1px) !important;
}

.public-DraftEditorPlaceholder-root {
  padding: 10px !important;
}

.public-DraftEditor-content[contenteditable='true'] {
  padding: 10px;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.rdw-option-wrapper {
  border: none !important;
}

.rdw-option-active {
  box-shadow: none !important;
  background-color: #e9d396 !important;
}

.rdw-colorpicker-modal {
  width: 260px !important;
}

.col-0-master-ingredient {
  width: 40%;
}
.col-1-master-ingredient {
  width: 40%;
}
.col-2-master-ingredient {
  width: 40%;
}
.col-3-master-ingredient {
  width: 30%;
}

.col-0 {
  width: 14%;
}
.col-0-cust {
  width: 8%;
}
.col-1-cust {
  width: 12%;
}
.col-2-cust {
  width: 20%;
}
.col-3-cust {
  width: 12%;
}
.col-4-cust {
  width: 15%;
}
.col-5-cust {
  width: 12%;
}
.col-6-cust {
  width: 10%;
}
.col-7-cust {
  width: 12%;
}

.col-0-temp {
  width: 10%;
}
.col-1-temp {
  width: 25%;
}
.col-2-temp {
  width: 15%;
}
.col-3-temp {
  width: 15%;
}
.col-4-temp {
  width: 15%;
}
.col-5-temp {
  width: 15%;
}
.col-6-temp {
  width: 8%;
}

.col-0-temp1 {
  width: 10%;
}
.col-1-temp1 {
  width: 25%;
}
.col-2-temp1 {
  width: 15%;
}
.col-3-temp1 {
  width: 15%;
}
.col-4-temp1 {
  width: 15%;
}
.col-5-temp1 {
  width: 20%;
}

.col-0-rep {
  width: 9%;
}
.col-1-rep {
  width: 12%;
}
.col-2-rep {
  width: 15%;
}
.col-3-rep {
  width: 12%;
}
.col-4-rep {
  width: 12%;
}
.col-5-rep {
  width: 13%;
}
.col-6-rep {
  width: 13%;
}
.col-7-rep {
  width: 14%;
}

.col-1 {
  width: 20%;
}

.col-2 {
  width: 35%;
}

.col-3 {
  width: 18%;
}

.col-4 {
  width: 13%;
}

.tracker_col-0 {
  width: 12%;
}

.tracker_col-1 {
  width: 15%;
}

.tracker_col-2 {
  width: 29%;
}

.tracker_col-3 {
  width: 18%;
}

.tracker_col-4 {
  width: 15%;
}
.tracker_col-5 {
  width: 15%;
}

.payment-0 {
  width: 13%;
}
.payment-1 {
  width: 15%;
}
.payment-2 {
  width: 10%;
}
.payment-3 {
  width: 20%;
}
.payment-4 {
  width: 10%;
}
.payment-5 {
  width: 10%;
}
.payment-6 {
  width: 10%;
}
.payment-7 {
  width: 10%;
}
.payment-8 {
  width: 13%;
}
.payment-9 {
  width: 10%;
}

.Analysis_col-0 {
  width: 10%;
}
.Analysis_col-1 {
  width: 10%;
}
.Analysis_col-2 {
  width: 10%;
}
.Analysis_col-3 {
  width: 10%;
}
.Analysis_col-4 {
  width: 10%;
}
.Analysis_col-5 {
  width: 10%;
}
.Analysis_col-6 {
  width: 10%;
}
.Analysis_col-7 {
  width: 10%;
}
.Analysis_col-8 {
  width: 10%;
}
.Analysis_col-9 {
  width: 10%;
}
.Analysis_col-10 {
  width: 10%;
}
.Analysis_col-11 {
  width: 10%;
}

.truncate-custom {
  position: relative;
  display: inline-block;
}

.truncate-custom.active {
  cursor: pointer;
}

.truncate-custom .tooltip {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 4px 8px;
  font-size: 14px;
  white-space: nowrap;
}
.card_color {
  background-color: rgba(230, 230, 230, 0.2);
}

.dropdown-up {
  transform: translateY(-110%);
}

.dropdown-down {
  transform: translateY(0);
}

/* .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #d2a62c !important;
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #d2a62c !important;
}

.css-1g7nc1s-MuiPickersLayout-root .MuiPickersLayout-toolbar {
  background-color: #d2a62c !important;
  color: #fff;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  color: #d2a62c !important;
} */

.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover {
  will-change: background-color;
  background-color: #d2a62c !important;
}

/* .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 20px !important;
} */

.onhover {
  position: relative;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.onhover:hover {
  transform: translateX(-148px);
}

.consulation-guideline {
  position: absolute;
  top: 75%;
  left: 98px;
}

.onhover-view {
  position: relative;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.onhover-view:hover {
  transform: translateX(-68px);
}

.view-note {
  position: absolute;
  top: 22%;
  left: 58px;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #888;
}

/* Add this CSS in your stylesheet */
.resizable-layout {
  display: flex;
  align-items: stretch;
  width: 100%;
}

.resizable-column {
  overflow: auto;
}

.vertical {
  cursor: col-resize;
}

/* When resizing is in progress */
.resizing {
  user-select: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* .css-zs07ro-MuiInputBase-root-MuiFilledInput-root {
  background-color: white !important;
  color: black !important;
  font-weight: 500 !important;
  font-size: 12px !important;
}
.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
  padding: 11px 0 10px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 12px !important;
  padding-right: 0;
  padding-bottom: 8px;
  padding-left: 12px;
} */

/* .css-i4bv87-MuiSvgIcon-root {
  color: rgb(7, 6, 6) !important;
}
.css-zs07ro-MuiInputBase-root-MuiFilledInput-root.Mui-error:before {
  border-bottom-color: black !important;
}
.MuiInputBase-root-MuiFilledInput-root.Mui-error:after {
  border-bottom-color: black !important;
}
.css-zs07ro-MuiInputBase-root-MuiFilledInput-root:after {
  border-bottom-color: black !important;
  border-bottom: 1px !important;
}
.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
  background-color: #bd9528 !important;
  border: 1px solid black !important;
}

.css-zs07ro-MuiInputBase-root-MuiFilledInput-root.Mui-disabled:before {
  border-bottom-style: none !important;
}
.css-1gctnaj-MuiInputBase-input-MuiFilledInput-input.Mui-disabled {
  -webkit-text-fill-color: black !important;
  font-size: 12px !important;
  font-weight: 500 !important ;
} */
