input[type='checkbox'],
input[type='radio'] {
  --active: #bd9528;
  --active-inner: #fff;
  --border: #000000;
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 2px solid var(--bc, var(--border));
  background: var(--b, var(--background));

  &:after {
    content: '';
    position: absolute;
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
}

input[type='checkbox'] {
  &:not(.switch) {
    border-radius: 2px;

    &:after {
      width: 6px;
      height: 9px;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 4px;
      top: 1px;
      transform: rotate(var(--r, 20deg));
    }

    &:checked {
      --r: 43deg;
    }
  }
}

.mantine-Text-root {
  font-size: 12px;
  font-weight: 400;
  font-family: 'Poppins';
  line-height: 18px;
}

.react-aria-TimeField {
  display: flex;
  align-items: center;
}

.react-aria-DateInput {
  --active: #bd9528;
  --active-inner: #fff;
  --border: #000000;
  --highlight-background: var(--active);
  --highlight-foreground: white;
  --highlight-background-invalid: red;
  display: flex;
  gap: 10px;

  .react-aria-DateSegment {
    padding: 0 2px;
    font-size: 1rem;
    border-bottom: 1px solid #e6e6e6;

    text-align: end;

    &[data-type='literal'] {
      padding: 0;
      border-bottom: 0px;
    }

    &[data-type='dayPeriod'] {
      text-transform: uppercase;
    }

    &:focus {
      color: var(--active);
      background: var(--active-inner);
      outline: none;
      border-radius: 4px;
      caret-color: transparent;
    }

    &[aria-invalid] {
      &:focus {
        background: red;
        color: white;
      }
    }
  }
}

.diet-plan-screen {
  height: calc(100vh - 318px) !important;
}

@media (min-width: 900px) and (max-width: 1200px) {
  .diet-plan-screen {
    height: calc(100vh - 290px) !important;
  }
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .diet-plan-screen {
    height: calc(100vh - 280px) !important;
  }
}

@media (min-width: 1300px) and (max-width: 1900px) {
  .diet-plan-screen {
    height: calc(100vh - 280px) !important;
  }
}



.note-icon {
  transform: translate(-50%, -50%);
}

/*
@media only screen and (max-width: 1308px) {
  .diet-plan-screen {
    height: calc(100vh - 358px) !important;
  }
}

@media only screen and (max-width: 1190px) {
  .diet-plan-screen {
    height: calc(100vh - 378px) !important;
  }
}*/