@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
@import url(assets/style/style.scss);

body {
  font-family: 'poppins';
}

.main-page-height {
  height: calc(100vh - 100px);
}

/* .main-page-height-without-header {
  height: calc(100vh - 38px);
} */
